import Cropper from 'cropperjs';
import fr from './plugin/plugin-i10n-fr'
import en from './plugin/plugin-i10n-en'

export class FileImageCropper {

    constructor(modalCropperId, inputName, aspectRatio, minWidth, minHeight, lang) {
        this.minWidth = Number(minWidth);
        this.minHeight = Number(minHeight);
        this.aspectRatio = eval(aspectRatio);

        this.scaleFactor = 1.0;
        this.initialScaleFactor = 1.0;
        this.relativeMinWidth = this.minWidth;
        this.relativeMinHeight = this.minHeight;

        this.image = { width: 0, height: 0 }

        this.invertedDimensions = false;
        this.isRotationPossible = true;
        this.defaultTransformations = null;
        this.continueCropping = false;
        this.debounce = false;
        this.boundsBuffer = 2;

        this.t = lang === 'fr' ? fr : en;

        this.getDOMElements(modalCropperId, inputName);
        this.setDefaults();
        this.setEventListeners();
    }

    getDOMElements = (modalCropperId, inputName) => {
        this.modal = document.querySelector(modalCropperId);
        this.fileInput = document.querySelector(`${modalCropperId}_upload_input`);

        this.result = document.querySelector(`${modalCropperId} input[name=${inputName}]`);
        this.thumbnail = document.querySelector(`${modalCropperId}_thumbnail .file-image-thumbnail`);
        this.error = document.querySelector(`${modalCropperId} .size-error`);

        this.cropperCanvas = document.querySelector(`${modalCropperId} cropper-canvas`);
        this.cropperImage = document.querySelector(`${modalCropperId} cropper-image`);
        this.cropperSelection = document.querySelector(`${modalCropperId} cropper-selection`);

        this.transformPanel = document.querySelector(`${modalCropperId} .transform-actions-container`);
        this.backgroundColorPicker = document.querySelector(`${modalCropperId} .bg-color-picker`);
        this.resetButton = document.querySelector(`${modalCropperId} .reset-image`);
        this.flipHorizontalButton = document.querySelector(`${modalCropperId} .flip-horizontal-image`);
        this.flipVerticalButton = document.querySelector(`${modalCropperId} .flip-vertical-image`);
        this.rotateRightButton = document.querySelector(`${modalCropperId} .rotate-right-image`);
        this.rotateLeftButton = document.querySelector(`${modalCropperId} .rotate-left-image`);
        this.zoomInButton = document.querySelector(`${modalCropperId} .zoom-in-image`);
        this.zoomOutButton = document.querySelector(`${modalCropperId} .zoom-out-image`);

        this.submitButton = document.querySelector(`${modalCropperId} .submit-file-image`);
        this.cancelButtons = document.querySelectorAll(`${modalCropperId} .cancel-file-image`);
    }

    resize() {
        return new Promise((resolve, reject) => {
            let canvas = document.createElement("canvas");
            let ctx = canvas.getContext("2d");

            let width = this.originalImage.width;
            let height = this.originalImage.height;

            if (width < height && width > 1000) {
                let ratio = 1000/width;
                width = width * ratio;
                height = height * ratio;
            }
            else if (width > height && height > 1000) {
                let ratio = 1000/height;
                width = width * ratio;
                height = height * ratio;
            }

            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(this.originalImage, 0, 0, width, height);

            this.originalImage.onload = () => {
                resolve();
            }
            this.originalImage.src = canvas.toDataURL("image/png");
        })
    }

    rotate(degree) {
        return new Promise((resolve, reject) => {
            this.invertedDimensions = !this.invertedDimensions;

            let canvas = document.createElement("canvas");
            let ctx = canvas.getContext("2d");
            canvas.width = this.copyImage.height;
            canvas.height = this.copyImage.width;
            this.cropperSelection.$reset();

            ctx.save();
            ctx.translate(canvas.width / 2, canvas.height / 2);
            ctx.rotate(degree * Math.PI / 180);
            ctx.drawImage(this.copyImage, -this.copyImage.width / 2, -this.copyImage.height / 2);
            ctx.restore();
            this.copyImage.onload = () => {
                resolve();
            }
            this.copyImage.src = canvas.toDataURL("image/jpg", 1.0);
        })
    }

    setDefaults = () => {
        this.cropperCanvas.style.minWidth = "100%";
        this.cropperCanvas.style.minHeight = "100%";
        this.backgroundPickedColor = this.backgroundColorPicker.value;
        this.cropperCanvas.style.backgroundColor = this.backgroundPickedColor;
        this.cropperCanvas.scaleStep = 0;

        this.cropperSelection.style.minWidth = this.relativeMinWidth + "px";
        this.cropperSelection.style.minHeight = this.relativeMinHeight + "px";
        this.cropperSelection.defaultWidth = 0.0;
        this.cropperSelection.defaultHeight = 0.0;
    }

    setTrueSize = () => {
        let hasRotated = false;

        if (Math.abs(this.cropperImage.$getTransform()[1]) != 0.0) {
            this.scaleFactor = Math.abs(this.cropperImage.$getTransform()[1]);
            hasRotated = true;
        }
        else {
            this.scaleFactor = Math.abs(this.cropperImage.$getTransform()[0]);
        }

        let diff = 1;
        if (this.scaleFactor < this.initialScaleFactor) {
            diff -= (this.initialScaleFactor - this.scaleFactor) / this.initialScaleFactor;
        }
        else if (this.scaleFactor > this.initialScaleFactor) {
            diff += (this.scaleFactor - this.initialScaleFactor) / this.initialScaleFactor;
        }

        this.cropperCanvas.style.minWidth = this.image.width + "px";
        this.cropperCanvas.style.minHeight = this.image.height + "px";
        this.cropperCanvas.style.width = this.image.width + "px";
        this.cropperCanvas.style.height = this.image.height + "px";

        let transforms = this.cropperImage.$getTransform();

        if (hasRotated) {
            transforms[1] = transforms[1] > 0.0 ? diff : -diff;
            transforms[2] = transforms[2] > 0.0 ? diff : -diff;
        }
        else {
            transforms[0] = transforms[0] > 0.0 ? diff : -diff;
            transforms[3] = transforms[3] > 0.0 ? diff : -diff;
        }

        this.cropperImage.$setTransform(transforms);
        this.cropperImage.$center();

        this.cropperSelection.width = this.cropperSelection.width / this.initialScaleFactor;
        this.cropperSelection.height = this.cropperSelection.height / this.initialScaleFactor;
        this.cropperSelection.x = (this.cropperSelection.x  / this.initialScaleFactor);
        this.cropperSelection.y = (this.cropperSelection.y / this.initialScaleFactor);
    }

    loadCropper() {
        this.cropperCanvas.style.display = "block";
        this.image.width = this.copyImage.width;
        this.image.height = this.copyImage.height;

        let modalWidth = window.innerWidth * 0.8;
        let modalHeight = window.innerHeight * 0.8;

        this.scaleFactor = 1.0;
        this.continueCropping = true;

        if (this.copyImage.width < this.minWidth && this.copyImage.height < this.minHeight) {
            this.continueCropping = false;
        }
        else if (this.copyImage.width > modalWidth) {
            this.scaleFactor = (1.0 * modalWidth / this.copyImage.width);

            while (this.copyImage.height * this.scaleFactor > modalHeight) {
                this.scaleFactor -= 0.005;
            }
        }
        else if (this.copyImage.height > modalHeight) {
            this.scaleFactor = (1.0 * modalHeight / this.copyImage.height);

            while (this.copyImage.width * this.scaleFactor > modalWidth) {
                this.scaleFactor -= 0.005;
            }
        }

        if (this.copyImage.width < this.minHeight || this.copyImage.height < this.minWidth) {
            this.rotateLeftButton.style.display = "none";
            this.rotateRightButton.style.display = "none";
        }
        else {
            this.rotateLeftButton.style.display = "inline";
            this.rotateRightButton.style.display = "inline";
        }

        if (this.continueCropping) {
            this.copyImage.classList.add("cropper-image");
            this.cropperCanvas.style.minWidth = this.copyImage.width * this.scaleFactor + "px";
            this.cropperCanvas.style.minHeight = this.copyImage.height * this.scaleFactor + "px";
            this.cropperCanvas.style.width = this.copyImage.width * this.scaleFactor + "px";
            this.cropperCanvas.style.height = this.copyImage.height * this.scaleFactor + "px";

            this.cropperImage.src = this.copyImage.src;

            this.cropperImage.$ready(image => {
                this.cropperImage.$setTransform(this.scaleFactor, 0, 0, this.scaleFactor, 0, 0);
                this.cropperImage.$center();
                this.defaultTransformations = this.cropperImage.$getTransform();
                this.initialScaleFactor = this.scaleFactor;

                !this.modal.open && this.modal.showModal();

                this.resetCropperSelection();

                setTimeout(() => {
                    if (!this.invertedDimensions) {
                        this.cropperSelection.defaultWidth = this.cropperSelection.width;
                        this.cropperSelection.defaultHeight = this.cropperSelection.height;
                    }
                    this.resetCropperSelection();
                });
            });
        }
        else {
            this.showSizeError();
        }
    }

    setEventListeners = () => {
        this.submitButton.addEventListener("click", e => {
            e.preventDefault();

            if (this.continueCropping) {

                if (this.scaleFactor < 1.0) {
                    this.setTrueSize();
                }

                let croppedURL = null;
                this.cropperSelection.$toCanvas({
                    beforeDraw: (ctx) => {
                        ctx.fillStyle = this.backgroundPickedColor;
                        ctx.fillRect(0, 0, this.cropperSelection.width, this.cropperSelection.height);
                    },
                })
                    .then(canvas => {
                        croppedURL = canvas.toDataURL();
                        this.thumbnail.src = croppedURL;
                        this.result.value = croppedURL;
                    });
            }

            this.modal.close();
        });

        this.cancelButtons.forEach(btn => btn.addEventListener("click", e => {
            e.preventDefault();
            this.modal.close();
        }));

        this.flipHorizontalButton.addEventListener("click", e => {
            e.preventDefault();
            this.cropperImage.$scale(-1, 1);
        });

        this.flipVerticalButton.addEventListener("click", e => {
            e.preventDefault();
            this.cropperImage.$scale(1, -1);
        });

        this.rotateRightButton.addEventListener("click", e => {
            e.preventDefault();
            this.rotate(-90).then(r => {
                this.loadCropper();
                setTimeout(() => {
                    this.resetCropperSelection();
                })
            });
        });

        this.rotateLeftButton.addEventListener("click", e => {
            e.preventDefault();
            this.rotate(90).then(r => {
                this.loadCropper();
                setTimeout(() => {
                    this.resetCropperSelection();
                })
            });
        });

        this.zoomInButton.addEventListener("click", e => {
            e.preventDefault();
            this.cropperImage.$zoom(0.05);

        });

        this.zoomOutButton.addEventListener("click", e => {
            e.preventDefault();
            this.cropperImage.$zoom(-0.05);
        });

        this.fileInput.addEventListener("change", e => {
            if (e.target.files.length) {
                for (let i = 0; i < e.target.files.length; i++) {
                    const file = e.target.files[i];

                    if (file.type && !file.type.startsWith('image/')) {
                        console.log("File is not an image.", file.type, file);
                    }

                    const reader = new FileReader();

                    reader.addEventListener("load", e => {
                        this.error.style.display = "none";
                        this.submitButton.style.display = "inline";
                        this.transformPanel.style.display = "block";
                        this.cropperSelection.$reset();
                        this.cropperImage.$resetTransform();
                        this.originalImage = new Image();

                        let img = new Image();
                        img.src = e.target.result;
                        this.copyImage = img;
                        img.onload = () => {
                            img.onload = null;
                            this.originalImage.onload = () => {
                                this.originalImage.onload = null;
                                this.resize().then(() => {
                                    this.copyImage.onload = () => {
                                        this.copyImage.onload = null;
                                        this.loadCropper();
                                    }

                                    this.copyImage.src = this.originalImage.src;
                                });
                            }
                            this.originalImage.src = img.src;
                        }
                    });

                    reader.readAsDataURL(file);
                }
            };

            this.fileInput.value = null;
        });

        this.resetButton.addEventListener('click', e => {
            e.preventDefault();
            this.cropperSelection.$reset();
            this.copyImage.onload = () => {
                this.copyImage.onload = null;
                this.loadCropper();
                setTimeout(() => {
                    this.cropperSelection.width = this.cropperSelection.defaultWidth;
                    this.cropperSelection.height = this.cropperSelection.defaultHeight;
                    this.resetCropperSelection();
                });
            }
            this.copyImage.src = this.originalImage.src;
        });

        this.backgroundColorPicker.addEventListener('input', e => {
            this.backgroundPickedColor = e.target.value;
            this.cropperCanvas.style.backgroundColor = this.backgroundPickedColor;
        })

        this.cropperSelection.addEventListener('change', () => {
            this.isOutBounds();
        });
    }

    isOutBounds = () => {
        if (!this.debounce) {
            this.debounce = true;

            if (this.cropperSelection.height < this.relativeMinHeight) {
                setTimeout(() => this.cropperSelection.height = this.relativeMinHeight);
            }
            if (this.cropperSelection.width < this.relativeMinWidth) {
                setTimeout(() => this.cropperSelection.width = this.relativeMinWidth);
            }

            // let canvasWidth = this.cropperCanvas.getBoundingClientRect().width;
            // let canvasHeight = this.cropperCanvas.getBoundingClientRect().height;

            // if (this.cropperSelection.x < 0) {
            //     setTimeout(() => this.cropperSelection.$moveTo(this.boundsBuffer, this.cropperSelection.y));
            // }
            // else if (this.cropperSelection.x + this.cropperSelection.width > canvasWidth) {
            //     setTimeout(() => this.cropperSelection.$moveTo(canvasWidth - this.cropperSelection.width - this.boundsBuffer, this.cropperSelection.y));
            // }

            // if (this.cropperSelection.y < 0) {
            //     setTimeout(() => this.cropperSelection.$moveTo(this.cropperSelection.x, this.boundsBuffer));
            // }
            // if (this.cropperSelection.y + this.cropperSelection.height > canvasHeight) {
            //     setTimeout(() => this.cropperSelection.$moveTo(this.cropperSelection.x, canvasHeight - this.cropperSelection.height - this.boundsBuffer));
            // }


            setTimeout(() => this.debounce = false);
        }
    }

    resetCropperSelection = async () => {
        this.cropperSelection.aspectRatio = this.aspectRatio;

        this.relativeMinWidth = this.minWidth * this.scaleFactor;
        this.relativeMinHeight = this.minHeight * this.scaleFactor;

        this.cropperSelection.style.minWidth = this.minWidth * this.scaleFactor + "px";
        this.cropperSelection.style.minHeight = this.minHeight * this.scaleFactor + "px";

        let canvasWidth = this.cropperCanvas.getBoundingClientRect().width;
        let canvasHeight = this.cropperCanvas.getBoundingClientRect().height;

        if (this.aspectRatio >= 1) {
            if (canvasWidth > canvasHeight) {
                if (canvasWidth / canvasHeight < this.aspectRatio) {
                    this.cropperSelection.width = canvasWidth;
                }
                else {
                    this.cropperSelection.height = canvasHeight;
                }
            }
            else {
                this.cropperSelection.width = canvasWidth;
            }
        }
        else {
            if (canvasHeight > canvasWidth) {
                this.cropperSelection.width = canvasWidth;
            }
            else {
                this.cropperSelection.height = canvasHeight;
            }
        }

        this.cropperImage.$setTransform(this.defaultTransformations);
        this.cropperImage.$center();
        this.cropperSelection.$moveTo(0, 0)
        this.cropperSelection.$center();

        setTimeout(() => {
            this.cropperSelection.$render();
        })
    }

    showSizeError = () => {
        this.error.innerText = `${this.t["invalidImage"]}.\n
        ${this.t["tryMinWidth"]} ${this.minWidth}px ${this.t["tryMaxWidth"]} ${this.minHeight}px.\n`;

        this.cropperCanvas.style.display = "none";
        this.transformPanel.style.display = "none";
        this.submitButton.style.display = "none";
        this.error.style.display = "block";

        !this.modal.open && this.modal.showModal();
    }
}

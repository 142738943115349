const t = {
    "bio" : "Biographie",
    "discover" : "Découvrez-en davantage sur l'artiste",
    "website" : "Site Web",
    "process" : "Démarche",
    "showroom" : "Exposition virtuelle",
    "discoverOtherYears" : "Découvrez toutes nos cohortes d'artistes",
    "by" : "par",
    "back" : "Retour à la liste des artistes",
    "back-to-artist" : "Retour",
    "materials" : "Matériaux",
    "serialNumber" : "Numéro de série",
    "dimensions" : "Dimensions",
    "height" : "Haut.",
    "depth" : "Prof.",
    "width" : "Larg.",
    "duration" : "Durée",
    "video" : "Vidéo",
    "creation_year" : "Année de création",
    "medium" : "Médium",
    "sold" : "Vendu",
    "contact_form_text" : "Pour avoir plus d'informations sur l'oeuvre ou pour l'acheter, écrivez à l'artiste avec le formulaire suivant.",
    "send" : "Envoyer",
    "name" : "Nom",
    "email" : "Courriel",
    "message" : "Message",
    "emailSent" : "Courriel envoyé à l'artiste!",
    "artworks" : "Oeuvres",
    "subvention" : "Subvention",
    "callForProject" : "Appel à projet",
    "exposition" : "Exposition",
    "workshop" : "Atelier",
    "conference" : "Conférence",
    "all" : "Tous",
    "filterByType" : "Filter par catégorie",
    "close": "Fermer",
    "today": "Aujourd'hui",
    "showList": "Afficher liste",
    "hideList": "Masquer liste",
    "date" : "Date",
    "from" : "Du",
    "to" : "au",
    "invalidImage" : "Le format de l'image est invalide",
    "tryMinWidth" : "Veuillez réessayer avec une image d'une largeur minimale de",
    "tryMaxWidth" : "et d'une hauteur minimale de",
    "EventListTitle" : "Résumé des événements",
    "findOutMore" : "En savoir plus",
    "previousPage" : "Page précédente",
    "nextPage" : "Page suivante",
    "filterArtworkStateAll" : "Toutes les oeuvres",
    "filterArtworkStateAvailable" : "Oeuvres à vendre uniquement",
    "allPrices" : "Tous les prix",
    "over 500" : "Plus de 500$",
    "page" : "page",
    "of" : "de",
    "artwork count" : "oeuvres",
    "allArtists" : "Tous les artistes",
    "no artworks" : "Aucune oeuvre trouvée",
    "allYears" : "Toutes les cohortes",
}

export default t;
require('./bootstrap');
const SearchFilters = require('./search-filters');
import { FileImageCropper } from './file-image';

window.SearchFilters = SearchFilters;
window.FileImageCropper = FileImageCropper;

window.addEventListener("load", () => {
    document.querySelectorAll("video").forEach(vid => {
        vid.oncontextmenu = () => {
            return false;
        }
    })
});
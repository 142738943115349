
let searchData = {
	sortables : {},
	text : "",
	text2 : "",
	filters: {}
};

let ctrlDown = false;

export function setSearchData(data) {
	if (data != null && data != "null") {
		searchData = JSON.parse(data);

		if (searchData.filters == null || searchData.filters.length == 0) {
			searchData.filters = {};
		}

		if (searchData.sortables == null || searchData.sortables.length == 0) {
			searchData.sortables = {};
		}
	}

	document.onkeydown = e => {
		if (e.which == 17) {
			ctrlDown = true;
		}
	}

	document.onkeyup = e => {
		if (e.which == 17) {
			ctrlDown = false;
		}
	}

	$(function () {
		if ($("#pagination-total-amount").html().trim() == "") {
			$("#pagination-total-row").css("display", "none");
		}

		if (searchData.text != "") {
			$("#search-box").val(searchData.text);
		}

		if (searchData.text2 != "") {
			$("#search-box2").val(searchData.text2);
		}

		$('[data-sort]').each(function () {
			for (let key in searchData.sortables) {
				if (key == this.getAttribute("data-sort")) {
					if (searchData.sortables[key] == "ASC") {
						this.innerHTML += ' <span style="display: inline-block;" class="fa fa-angle-up"></span>';
					}
					else {
						this.innerHTML += ' <span style="display: inline-block;" class="fa fa-angle-down"></span>';
					}
				}
			}
		})

		$('[data-filter]').each(function () {
			
			for (let key in searchData.filters) {
				
				if (key == this.getAttribute("data-filter")) {
					if (this.getAttribute("data-option") != null) {
						if (SearchFilters.isFilterApplied(searchData.filters[key], this.getAttribute("data-option"))) {
							this.style.color = "black";
						}
					}
					else {
						if (searchData.filters[key].length > 0) {
							this.value = searchData.filters[key];
						}
					}
				}
			}

			if (searchData.filters[this.getAttribute("data-filter")] == null && 
				(this.getAttribute("data-option") == "Toutes" || this.getAttribute("data-option") == "Tous")) {
				this.style.color = "black";	
			}
		});
	});
}

export function isFilterApplied(keyFilters, option) {
	return keyFilters != null && (keyFilters.indexOf(option)) != -1;
}

export function applyFilter(e, elem) {
	if (searchData["filters"][elem.getAttribute("data-filter")] == null) {
		searchData["filters"][elem.getAttribute("data-filter")] = [];
	}

	let val = elem.getAttribute("data-option") == null ? elem.value : elem.getAttribute("data-option");
	let loc = searchData["filters"][elem.getAttribute("data-filter")].indexOf(val);

	if (elem.getAttribute("data-unique") != null) {
		if (loc == -1) {
			searchData["filters"][elem.getAttribute("data-filter")] = [val];
		}
		else {
			searchData["filters"][elem.getAttribute("data-filter")] = [];
		}
	}
	else if (loc == -1) {
		if (elem.getAttribute("data-option") == null) {
			if (val != "") {
				searchData["filters"][elem.getAttribute("data-filter")] = [val];
			}
			else {
				searchData["filters"][elem.getAttribute("data-filter")] = [];
			}
		}
		else {
			if (ctrlDown) searchData["filters"][elem.getAttribute("data-filter")].push(val);
			else searchData["filters"][elem.getAttribute("data-filter")] = [val];
		}
	}
	else {
		if (elem.getAttribute("data-option") == null) searchData["filters"][elem.getAttribute("data-filter")] = [];
		else searchData["filters"][elem.getAttribute("data-filter")].splice(loc, 1);
	}
		
	e.preventDefault();
	window.location.href="?search-data=" + JSON.stringify(searchData);
}

export function asCSV(url) {
	window.location.href = url + "?search-data=" + JSON.stringify(searchData);	
}

export function search(event, elem) {
	if (event.which == 13) {
		searchData["text"] = elem.value;
		window.location.href="?search-data=" + JSON.stringify(searchData);	
	}
}

export function search2(event, elem) {
	if (event.which == 13) {
		searchData["text2"] = elem.value;
		window.location.href="?search-data=" + JSON.stringify(searchData);	
	}
}

export function gotoWithSearchData(destination, extraData) {
	if (extraData != null) {
		for (let data in extraData) {
			searchData[data] = extraData[data];
		}
	}

	window.location.href= destination + "?search-data=" + JSON.stringify(searchData);	
}

export function sort(elem) {
	let defaultOrdering = "ASC";

	if (elem.getAttribute("data-default-ordering") != null) {
		defaultOrdering = elem.getAttribute("data-default-ordering");	
	}

	let name = elem.getAttribute("data-sort");
	let isUnique = elem.getAttribute("data-unique-sort");
	
	if (isUnique == "true") {
		$tmp = searchData["sortables"][name];

		searchData["sortables"] = {};
		searchData["sortables"][name] = $tmp;
	}

	if (searchData["sortables"][name] != null) {
		if (searchData["sortables"][name] == "ASC") {
			searchData["sortables"][name] = "DESC";
		}
		else if (searchData["sortables"][name] == "DESC") {
			delete searchData["sortables"][name];
		}
	}
	else {
		searchData["sortables"][name] = defaultOrdering;
	}

	window.location.href="?search-data=" + JSON.stringify(searchData);
}